import { ListaSubMenuVO } from "./listaSubMenuVO";

export class ListaMenusVO {
        AMENIDADES: Menus;
        CATALOGOS: Menus;
        TELEFONOS: Menus;
        USUARIOS: Menus;

        constructor(data?: any){
                if(data){
                        this.AMENIDADES = data.AMENIDADES;
                        this.CATALOGOS = data.CATALOGOS;
                        this.TELEFONOS = data.TELEFONOS;
                        this.USUARIOS = data.USUARIOS;
                }
        }
}


export class Menus {
        descripcion: string;
        id: number;
        idNombre: string;
        listaSubMenu: ListaSubMenuVO[];
        icontype: string;
        path: string;
}