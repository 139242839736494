import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'btn-create',
  templateUrl: './button-create.component.html',
  styleUrls: ['./button-create.component.css']
})
export class ButtonCreateComponent {

  @Output() crearEmit = new EventEmitter<any>();

  constructor() { }

  crear() {
    this.crearEmit.emit();
  }
}
