import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LifeTimeSesionService } from 'src/app/services/life-time-session.service';
import { Subscription } from 'rxjs';

/**
 * Component session dialog created by: irvin uriel
 */

@Component({
  selector: 'app-session-dialog',
  templateUrl: './session-dialog.component.html',
  styleUrls: ['./session-dialog.component.css']
})
export class SessionDialogComponent implements OnInit, OnDestroy {
  count: number
  countObserber: Subscription;
  constructor(public dialogRef: MatDialogRef<SessionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _life: LifeTimeSesionService) { 
      this.countObserber = this._life.getwarningCount().subscribe(count => this.count = count);
    }
    
    ngOnInit() {
    }
    
    onNoClick(): void {
      this._life.logOut();
      this.dialogRef.close();
    }
    
    refreshToken(){
      this._life.resetToken();
    }

    ngOnDestroy(): void {
      if(this.countObserber){
        this.countObserber.unsubscribe();
      }
    }
}
