import { ErrorVO } from '../vo/errorVO';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ErrorUtils {

    constructor(
    ) { }

    createError(message: string): ErrorVO {
        let errorVO : ErrorVO;
        errorVO = new ErrorVO();
        errorVO.message = message;
        return errorVO;
    }
}