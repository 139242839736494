// general imports
import { Injectable } from '@angular/core';
import { Subject, throwError, Observable } from 'rxjs';
import * as jwt_decode from "jwt-decode";
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { notificationMessage } from './../common/utils/configUrl';

// imports VO
import { ErrorUtils } from '../common/utils/errorUtils';
import { ErrorVO } from "../common/vo/errorVO";
import { TokenVO } from '../common/vo/tokenVO';
import { ResponseVO } from '../common/vo/responseVO';
import { ModalData } from '../common/vo/modalData';

// components
import { ListaMenusVO, Menus } from '../common/vo/listaMenusVO';

@Injectable({
  providedIn: 'root',
})
export class DataService {

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private errorUtils: ErrorUtils
  ) { }
  /**
   * Observable para enviar mensajes de tipo alerta
   */
  private notificationMessage = new Subject<NotificationMessageModel>();

  /**
   * Observable para enviar animacion de loading en pantalla
   */
  private isLoading = new Subject<boolean>();

  /**
   * boleano para validar si la pagina es cargada por primera vez
   */
  private refreshPage: boolean = false;

  /**
   * Informacion del usuario
   */
  private infoLogin = new ListaMenusVO();
 
  /**
   * Informacion del usuario
   */
  private token: string;

  /**
   * Informacion de la instancia del componente para mostrar en dialogo
   */
  private modalData = new Subject<ModalData>();

  /**
   * actualiza informacion de la tabla de un componente
   */
  private fecthData = new Subject<boolean>();

  /**
   * actualiza informacion de la tabla de un componente
   */
  private menuItems: Menus[];

  //-----------------------------Getters and Setters-----------------------------//

  /**
	 * retorna informacion de la tabla de un componente
	 * @return this.fecthData
	 */
  getFecthData() : Observable<boolean> {
    return this.fecthData.asObservable();
  }

  /**
	 * guarda informacion de la tabla de un componente
	 * @param infoLogin
	 */
  setFetchData(data: boolean) : void {
    this.fecthData.next(data);
  }

  /**
	 * guarda informacion en el localStorage
	 * @param nombre
   * @param valor
	 */
  addStore(nombre: string, valor:any){
    let valorString = JSON.stringify(valor);
    localStorage.setItem(nombre, valorString);
  }

  /**
	 * recuperar variable en localStorage
	 * @param nombre
	 */
  retriveStorage(nombre: string) : any{
    let getData = localStorage.getItem(nombre);
    return JSON.parse(getData);
  }

  /**
	 * retorna la informacion de la instancia del componente para mostrar en dialogo
	 * @return this.infoLogin
	 */
  getModalData() : Observable<ModalData> {
    return this.modalData.asObservable();
  }

  /**
	 * guarda informacion de la instancia del componente para mostrar en dialogo
	 * @param infoLogin
	 */
  setModalData(data: ModalData): void {
    this.modalData.next(data);
  }

  /**
	 * retorna la informacion del usuario
	 * @return this.infoLogin
	 */
  getInfoLogin(): ListaMenusVO {
    return this.infoLogin;
  }

  /**
	 * guarda informacion del usuario
	 * @param infoLogin
	 */
  setInfoLogin(infoLogin: ListaMenusVO):void{
      this.infoLogin = infoLogin;
  }

  /**
	 * retorna el mensaje de error como observable 
	 * @return this.generalNotificationMessage
	 */
  getNotificationMessage():Observable<NotificationMessageModel> {
      return this.notificationMessage.asObservable();
  }

  /**
	 * guarda informacion del mensaje de error
	 * @param msg
	 */
  setErrorNotificationMessage(msg: ErrorVO):void{
    let modelNotification = new NotificationMessageModel();
    modelNotification.message = msg.message;
    modelNotification.code = msg.code;
    modelNotification.type = 'danger';
    modelNotification.icon = 'error';
    this.notificationMessage.next(modelNotification);
  }

  /**
	 * guarda informacion del mensaje de error
	 * @param msg
	 */
  setSuccessNotificationMessage(msg: string):void{
    let modelNotification = new NotificationMessageModel();
    modelNotification.message = msg;
    modelNotification.type = 'success';
    modelNotification.icon = 'success';
    this.notificationMessage.next(modelNotification);
}

  /**
	 * retorna boleano para habilitar o deshabilitar animacion de loading
	 * @return this.isLoading
	 */
  getIsLoadingEvent():Observable<boolean>{
    return this.isLoading.asObservable();
  }

  /**
   * guarda boleano para habilitar o deshabilitar animacion de loading
   * @param isLoading
	 */
  setIsLoadingEvent(isLoading: boolean):void{
    this.isLoading.next(isLoading);
  }

  /**
   * Se encapsula el observer que actualizará la bandera para mostrar el loading.
   */
  stopLoading():void {
    this.setIsLoadingEvent(false);
  }

  startLoading():void {
    this.setIsLoadingEvent(true);
  }
  /**
	 * retorna boleano para validar si la pagina es cargada por primera vez
	 * @return this.refreshPage
	 */
  getRefreshPage():boolean {
    return this.refreshPage;
  }

  /**
	 * guarda boleano para validar si la pagina es cargada por primera vez
	 * @param refreshPage
	 */
  setRefreshPage(refreshPage: boolean):void {
      this.refreshPage = refreshPage;
  }
  
  /**
	 * retorna token
	 * @return this.refreshPage
	 */
  getToken():string {
    return this.token;
  }

  /**
	 * guarda token
	 * @param token
	 */
  setToken(token: string):void {
    localStorage.setItem('token',token);
    this.token = token;
  }

  /**
   * se obtiene el titulo de la pagina a visualizar
   */
  getMenuItems(): Menus[]{
    return this.menuItems;
  }

  /**
   * se guarda el titulo de la pagina a visualizar
   * @param menuItems : titulo de pa pagina
   */
  setMenuItems(menuItems: Menus[]):void{
    this.menuItems = menuItems;
  }

  /**
   * decodifica token que esta almacenado en el local storage
   * @return tokenEncoded
   */
  decodeToken(): TokenVO{
    let tokenEncoded = new TokenVO();
    let token = this.getTokenStorage();
    if(!token)
      return null;
    tokenEncoded = jwt_decode(localStorage.getItem('token'));
    // tokenEncoded.TOKEN_DATA.listaMenus.push(tokenEncoded.TOKEN_DATA.listaMenus[0]);
    // tokenEncoded.TOKEN_DATA.listaMenus[0].path = '/contact'
    console.log(tokenEncoded);

    return tokenEncoded;
  }

  /**
   * manda token almacenado en el local storage
   * @return token
   */
  private getTokenStorage(): string{
    let token = localStorage.getItem('token');
    return token;
  }

  /**
   * Metodo principal de manejo de errores, este metodo es el
   * que debe tener la funcionalidad de bitacoreo y lo que se
   * requiera
   * 
   * Muestra los errores de los componentes
   */
  addErrors(errorList: ErrorVO[]):void {
    errorList.forEach(error =>
      this.setErrorNotificationMessage(error)
    );
  }

  /**
   * Agrega un error formato string, ejemplo:
   * error = 'Ocurrio un error'
   */
  addError(error: string):void {
    let errorList: ErrorVO[] = [];
    errorList.push(this.errorUtils.createError(error));
    this.addErrors(errorList);
  }

  /**
   * captura los errores de las peticiones rest.
   * 
   * nota: handleError no detecta llamadas a otros metodos
   */
  handleError(errorResponse: HttpErrorResponse):Observable<never> {
    console.log(errorResponse);
    
    let errorList: ResponseVO<null> = errorResponse.error;
    if(errorList.errors){
      return throwError(errorList.errors);
    }
    let error: ErrorVO[] = [];
    let errorVO : ErrorVO;
    errorVO = new ErrorVO();
    errorVO.message = notificationMessage.CONECTION_FAILED;
    error.push(errorVO);
    return throwError(error);
  }
}

/**
 * Modelo para mostrar mensaje de notificación.
 */
export class NotificationMessageModel{
  message: string;
  type: string;
  icon: string;
  code: string;
}