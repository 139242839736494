import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TelefonosService } from '../../../../services/telefonos.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../../../services/data.service';
import { ErrorVO } from '../../../../common/vo/errorVO';
import { TranslateService } from '@ngx-translate/core';
import { REGEX } from './../../../../common/utils/configUrl';
import { IdiomaVO } from 'src/app/common/vo/idiomaVO';
import { RequestVO } from 'src/app/common/vo/requestVO';
import { CatalogoVO } from 'src/app/common/vo/catalogoVO';

@Component({
  selector: 'app-detalle-telefonos',
  templateUrl: './detalle-telefonos.component.html'
})
export class DetalleTelefonosComponent implements OnInit {
  public formGroup: FormGroup;
  idInstance: number;
  selectedIdioma: IdiomaVO;
  idiomaList: IdiomaVO[] = [
    {idNombre: 'es_MX', descripcion: 'Español México', id:null},
    {idNombre: 'en_US', descripcion: 'Ingles USA', id:null}
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public _data: any,
              public _dialogRef: MatDialogRef<DetalleTelefonosComponent>,
              private _tipoTelefonoService: TelefonosService,
              private translate: TranslateService,
              private _dataService: DataService,
              private _formBuilder: FormBuilder) {
                this.idInstance = +_data.id;
              }

  public ngOnInit() {
    // Se llama el metodo que inicializa el formGroup, para controlar las validaciones del formulario.
    this.buildForm();
  }

  // Configura las instancias necesarias para controlar el estatus de los elementos del formulario.
  private buildForm() {
    this.formGroup = this._formBuilder.group({
      id: [this._data.id],
      idNombre: [this._data.idNombre , [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(REGEX.letrasNumeros)]],
      descripcion: [this._data.descripcion , [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(REGEX.letrasNumeros)]],
      idioma: this._data.idioma == null ? [this._data.idioma , [Validators.required]] : [this._data.idioma.idNombre , [Validators.required]]
    });
  }

  // Getters para poder utilizar su referencia en el template.
  get idNombre() {
    return this.formGroup.get('idNombre');
  }
  get descripcion() {
    return this.formGroup.get('descripcion');
  }
  get idioma() {
    return this.formGroup.get('idioma');
  }

  // Envia la solicitud de actualizacion de los elementos del formulario.
  actualizar() {
    this._dataService.startLoading();
    this.formGroup.setValue({
      id: this.formGroup.value.id,
      idNombre: this.formGroup.value.idNombre,
      descripcion: this.formGroup.value.descripcion,
      idioma: {
        idNombre: this.formGroup.value.idioma
      }
    });
    this._tipoTelefonoService.tiposTelefonosUpdate(this.formGroup.getRawValue()).subscribe(
      (response) => {
        // Valida la respuesta recibida por la solicitud de actualizacion.
        if ( response.success ) {
          // Si el proceso fue exitoso, envia un mensaje al servicio encargado
          // de la actualizacion de la data del listado en el componente padre.
          this._dataService.setFetchData(true);
          this._dataService.stopLoading();
          // Cierra el dialogo, una vez pasada la transaccion
          this.cerrar();
          // Obtiene el valor del mensaje en el lenguaje correspondiente
          const message = this.translate.instant('common.alert.item.updated.succes');
          this._dataService.setSuccessNotificationMessage(message);
        } else {
          this._dataService.stopLoading();
          this._dataService.addErrors(response.errors);
        }
      },
      (error: ErrorVO[]) => {
        this._dataService.stopLoading();
        this._dataService.addErrors(error);
      }
    );
  }

  // Envia la solicitud de creacion de los elementos del formulario.
  crear() {
    this._dataService.startLoading();
    this.formGroup.setValue({
      id: this.formGroup.value.id,
      idNombre: this.formGroup.value.idNombre,
      descripcion: this.formGroup.value.descripcion,
      idioma: {
        idNombre: this.formGroup.value.idioma
      }
    });
    let params = new RequestVO<CatalogoVO>();
    params.parameters = this.formGroup.getRawValue();
    this._tipoTelefonoService.tiposTelefonosCreate(params).subscribe(
      (response) => {
        // Valida la respuesta recibida por la solicitud de creacion.
        if ( response.success ) {
          // Si el proceso fue exitoso, envia un mensaje al servicio encargado
          // de la actualizacion de la data del listado en el componente padre.
          this._dataService.setFetchData(true);
          this._dataService.stopLoading();
          // Cierra el dialogo, una vez pasada la transaccion
          this.cerrar();
          // Obtiene el valor del mensaje en el lenguaje correspondiente
          const message = this.translate.instant('common.alert.item.created.succes');
          this._dataService.setSuccessNotificationMessage(message);
        } else {
          this._dataService.stopLoading();
          this._dataService.addErrors(response.errors);
        }
      },
      (error: ErrorVO[]) => {
        this._dataService.stopLoading();
        this._dataService.addErrors(error);
      });
  }

  cerrar() {
    this._dialogRef.close();
  }

}
