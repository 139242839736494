import { Injectable } from '@angular/core';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class EncryptService {  
  
  private encryptKey:string;
  private iv: string;
  private salt: string;
  private keySize: number;
  private iterationCount: number;
  private key: Variable;

  //inicializacion de variables.
  constructor(){
    this.encryptKey = 'y01n'
    this.keySize = 128 / 32;
    this.iterationCount = 1000;
    this.iv = CryptoJS.lib.WordArray.random(128/8).toString(CryptoJS.enc.Hex);
    this.salt = CryptoJS.lib.WordArray.random(128/8).toString(CryptoJS.enc.Hex);
    this.key = CryptoJS.PBKDF2(
      this.encryptKey, 
      CryptoJS.enc.Hex.parse(this.salt),
      { keySize: this.keySize, iterations: this.iterationCount });
  }

  //Metodo de encriptacion de datos JSON
  public encryptJSON(data: Object):string {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptKey).toString();
    } catch (e) {
      console.log(e);
    }
  }

  //Metodo de desencriptacion de datos JSON
  public decryptJSON(data: string): Object {
    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  //Metodo de encriptacion de la contraseña para el servicio del backend.
  public encryptDataToBack(stringToEncrypt: string):string {
    try {
      var ciphertext1 = this.encrypt(stringToEncrypt);
      var ciphertext = btoa(this.iv + "::" + this.salt + "::" + ciphertext1);
      return ciphertext.toString();
    } catch (e) {
      console.log(e);
    }
  }
  
  //Metodo de encripcion para una cadena.
  public encrypt(plainText: string) {
    var encrypted = CryptoJS.AES.encrypt(
        plainText,
        this.key,
        { iv: CryptoJS.enc.Hex.parse(this.iv) });
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  }
  
  //Metodo de desencripcion de una cadena.
  public decrypt(cipherText: string) {
    var cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(cipherText)
    });
    var decrypted = CryptoJS.AES.decrypt(
        cipherParams,
        this.key,
        { iv: CryptoJS.enc.Hex.parse(this.iv) });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

}
  