import { AbstractControl, ValidationErrors } from '@angular/forms';

export const emailMatcher = (control: AbstractControl ): ValidationErrors | null => {
    const userIdEmail = control.get('userIdEmail');
    const confirmEmail = control.get('confirmEmail');
    if ( !userIdEmail || !confirmEmail || userIdEmail.value === null || confirmEmail.value === null ) { return null; }
    // if( userIdEmail.value === ''){ return {nomatch: true} }
    // if( confirmEmail.value === ''){ return {nomatch: true} }
    return userIdEmail.value.toLowerCase() === confirmEmail.value.toLowerCase() ? null : { nomatch: true };
};

export const phoneMatcher = (control: AbstractControl ): ValidationErrors | null => {
    const userIdPhone = control.get('userIdPhone');
    const confirmPhone = control.get('confirmPhone');
    if ( !userIdPhone || !confirmPhone || userIdPhone.value === null || confirmPhone.value === null ) { return null; }
    // if( userIdPhone.value === ''){ return {nomatch: true} }
    // if( confirmPhone.value === ''){ return {nomatch: true} }
    return userIdPhone.value.toLowerCase() === confirmPhone.value.toLowerCase() ? null : { nomatch: true };
};

export const passwordMatcher = (control: AbstractControl ): ValidationErrors => {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');
    if ( !password || !confirmPassword ) { return null; }

    return password.value === confirmPassword.value ? null : { nomatch: true };
};
