import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { DataService } from '../services/data.service';
import { Menus } from '../common/vo/listaMenusVO';
import { HomePages } from '../common/utils/configUrl';
import { HomePage } from '../common/models/homePage';
import { SesionService } from '../services/sesion.service';

declare const $: any;
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: Menus[] = new Array();
    public homePages: HomePage[] = HomePages;
    public user: string;

    constructor(private _data: DataService, private _sesion:SesionService){
        let menuItems = this._data.getInfoLogin();


        this.user = this._sesion.getUsuario();


        let menus = Object.keys(menuItems);
        menus.forEach(menu => {
            switch (menu) {
                case 'AMENIDADES':
                    menuItems.AMENIDADES.icontype = 'home'
                    this.menuItems.push(menuItems.AMENIDADES);
                    break;
                case 'CATALOGOS':
                    menuItems.CATALOGOS.icontype = 'apps';
                    this.menuItems.push(menuItems.CATALOGOS);
                    break;
                case 'TELEFONOS':
                    menuItems.TELEFONOS.icontype = 'phone';
                    this.menuItems.push(menuItems.TELEFONOS);
                    break;
                case 'USUARIOS':
                    menuItems.USUARIOS.icontype = 'people';
                    this.menuItems.push(menuItems.USUARIOS);
                    break;
                default:
                    menuItems[menu].icontype = 'close';
                    this.menuItems.push(menuItems.USUARIOS);
                    break;
            }
        });
        console.log(this.menuItems);
        this._data.setMenuItems(this.menuItems);
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    ngOnInit() { }
    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            let ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: false });
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
}
