// general imports
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { endpoints, httpOptions } from '../common/utils/configUrl';

// imports VO
import { RequestVO } from '../common/vo/requestVO';
import { ResponseVO } from '../common/vo/responseVO';
import { UserVO } from '../common/vo/userVO';
import { UserLoginResponseVO } from '../common/vo/userLoginResponseVO';

// sevices
import { DataService } from './data.service';


@Injectable({
  providedIn: 'root'
})
export class SesionService {

  private _url: string = endpoints.Login;
  private _urlValidateToken: string = endpoints.ValidateLogin;
  private _urlRefreshToken: string = endpoints.RefreshToken;
  private usuario;

  constructor(
    private _http: HttpClient,
    private _data: DataService
  ) { }

  login(usuario: string, password: string): Observable<ResponseVO<UserLoginResponseVO>> {
    const requestVO: RequestVO<UserVO> = new RequestVO<UserVO>();
    const user = new UserVO;
    user.usuario = usuario;
    user.password = password;
    user.isMobile = false;
    user.i18n = this.getNavigatorLanguage();
    requestVO.parameters = user;
    return this._http
      .post<ResponseVO<UserLoginResponseVO>>(this._url, requestVO, httpOptions)
      .pipe(catchError(this._data.handleError));
  }

  getUsuario(){
    return this.usuario;
  }

  setUsuario(usuario : string){
    this.usuario = usuario;
  }
  


  validateLogin(token:string):Observable<ResponseVO<UserLoginResponseVO>>{
    // crea httpOptions
    let httpOp =  {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token })
    }
    return this._http.get<ResponseVO<UserLoginResponseVO>>(this._urlValidateToken,httpOp).pipe(catchError(this._data.handleError));
  }

  refreshToken(token:string):Observable<ResponseVO<UserLoginResponseVO>>{
     // crea httpOptions
     let httpOp =  {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token })
    }
    return this._http.get<ResponseVO<UserLoginResponseVO>>(this._urlRefreshToken,httpOp).pipe(catchError(this._data.handleError));
  }

  getNavigatorLanguage(): string {
    let language = navigator.language;
    const regularExpresion = new RegExp('es');
    language = (regularExpresion.test(language)) ? 'es_MX' : 'en_US';
    return language;
  }
}
