import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-basic-search',
  templateUrl: './basic-search.component.html',
  styleUrls: ['./basic-search.component.css']
})
export class BasicSearchComponent {
  @Output() search = new EventEmitter<string>();

  constructor() { }

  buscar(searchText: string) {
    this.search.emit(searchText);
  }

}
