import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';

declare const $: any;
@Component({
    selector: 'app-menu-cmp',
    templateUrl: 'menu.component.html',
})

export class MenuComponent implements OnInit {
    

    /**

    $("body").on('click','[data-menu]',function(){
        const div = $(this).data('menu');
    
        $("#menu").slideUp(300);
        
        console.log(div);
        console.log("hola");
    
        $(".menu_order").removeClass("menu_seleccionado");
        $(".menu_order").css({"order":"999"});
        $("." + div).css({"order":"1"});
        $("." + div).addClass("menu_seleccionado");
    
    
        $("." + div).css({"order":"1"});
    
        $("." + div).addClass({"order":"1"});
    
        $('html, body').stop().animate({
            scrollTop: 0
        }, 1000);
    
    
    
        
    
        //NO FUNCIONA
        //$("div." + div + " > div.menu_contenido").css({"display":"inline"});
    
    
    
        //$(".menu_contenido").css({"display":"none"});
        //$("div." + div + " > div.menu_contenido").css({"display":"inline"});
    
    });
    */

    click_menu(data_menu){
        //const div = $(this).data('menu');
        const div = data_menu;
    

        $("#menu_fondo").fadeOut(100);

        $(".menu_order").removeClass("menu_seleccionado");
        $(".menu_order").css({"order":"999"});
        $("." + div).css({"order":"1"});
        $("." + div).addClass("menu_seleccionado");
    
    
        $("." + div).css({"order":"1"});

        

        document.querySelector('#menu_scroll').scrollTop = 0;

        /*
        $('#menu_scroll').stop().animate({
            scrollTop: 0
        }, 1000);
        */


        $("#menu").slideUp(100);
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    ngOnInit() { }
    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            let ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: false });
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
}
