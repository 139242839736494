import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-delete',
  templateUrl: './button-delete.component.html',
  styleUrls: ['./button-delete.component.css']
})
export class ButtonDeleteComponent {
  // Recibe el valor para validar si el usuario tiene o no, permisos para visualizar esta funcion.
  @Input() row: any;
  // Recibe el objeto al cual se le desea aplicar la funcion.
  @Input() permisosFunciones: any;
  // Se crea la instancia del EvenEmiter para notificar al componente padre el evento.
  @Output() eliminarEmit = new EventEmitter<any>();

  // [row]="row" [permisoEliminar]="permisoEliminarTipoTelefono" (eliminar)="eliminarTipoTelefono( $event )"
  constructor() { }

  eliminar() {
    this.eliminarEmit.emit(this.row);
  }

}
