import { IdiomaVO } from "./idiomaVO";

export class TokenVO {
    TOKEN_DATA: {
        apellidoMaterno: string;
        apellidoPaterno: string;
        fechaCreacion: Date;
        fechaExpiracion: Date;
        idUsuario: number;
        idiomaVO: IdiomaVO;
        isMobile: boolean;
        nombre: string;
        nombreUsuario: string;
    }
}