import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../../../../services/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MascotaService } from '../../../../services/mascota.service';
import { ErrorVO } from '../../../../common/vo/errorVO';
import { REGEX } from './../../../../common/utils/configUrl';

@Component({
  selector: 'app-detalle-tipo-mascota',
  templateUrl: './detalle-tipo-mascota.component.html',
})
export class DetalleTipoMascotaComponent implements OnInit {

  public formGroup: FormGroup;
  idInstance: number;

  constructor(@Inject(MAT_DIALOG_DATA) public _data: any,
              public _dialogRef: MatDialogRef<DetalleTipoMascotaComponent>,
              private _tipoMascotaService: MascotaService,
              private translate: TranslateService,
              private _dataService: DataService,
              private _formBuilder: FormBuilder) {
                // Variable para diferenciar entre la funcion de actualizar o crear objeto.
                // Undefined = crear | number = Actualizar
                this.idInstance = +_data.id;
              }

  public ngOnInit() {
    // Se llama el metodo que inicializa el formGroup, para controlar las validaciones del formulario.
    this.buildForm();
  }

  // Configura las instancias necesarias para controlar el estatus de los elementos del formulario.
  private buildForm() {
    this.formGroup = this._formBuilder.group({
      id: [this._data.id],
      idNombre: [this._data.idNombre , [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(REGEX.letrasNumeros)]],
      descripcion: [this._data.descripcion , [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(REGEX.letrasNumeros)]]
    });
  }

  // Getters para poder utilizar su referencia en el template.
  get idNombre() {
    return this.formGroup.get('idNombre');
  }
  get descripcion() {
    return this.formGroup.get('descripcion');
  }

  // Envia la solicitud de actualizacion de los elementos del formulario.
  actualizar() {
    this._dataService.startLoading();
    this._tipoMascotaService.tipoMascotaUpdate(this.formGroup.getRawValue()).subscribe(
      (response) => {
        // Valida la respuesta recibida por la solicitud de actualizacion.
        if ( response.success ) {
          // Si el proceso fue exitoso, envia un mensaje al servicio encargado
          // de la actualizacion de la data del listado en el componente padre.
          this._dataService.setFetchData(true);
          this._dataService.stopLoading();
          // Cierra el dialogo, una vez pasada la transaccion
          this.cerrar();
          // Obtiene el valor del mensaje en el lenguaje correspondiente
          const message = this.translate.instant('common.alert.item.updated.succes');
          this._dataService.setSuccessNotificationMessage(message);
        } else {
          this._dataService.stopLoading();
          this._dataService.addErrors(response.errors);
        }
      },
      (error: ErrorVO[]) => {
        this._dataService.stopLoading();
        this._dataService.addErrors(error);
      }
    );
  }

  // Envia la solicitud de creacion de los elementos del formulario.
  crear() {
    this._dataService.startLoading();
    this._tipoMascotaService.tipoMascotaCreate(this.formGroup.getRawValue()).subscribe(
      (response) => {
        // Valida la respuesta recibida por la solicitud de creacion.
        if ( response.success ) {
          // Si el proceso fue exitoso, envia un mensaje al servicio encargado
          // de la actualizacion de la data del listado en el componente padre.
          this._dataService.setFetchData(true);
          this._dataService.stopLoading();
          // Cierra el dialogo, una vez pasada la transaccion
          this.cerrar();
          // Obtiene el valor del mensaje en el lenguaje correspondiente
          const message = this.translate.instant('common.alert.item.created.succes');
          this._dataService.setSuccessNotificationMessage(message);
        } else {
          this._dataService.stopLoading();
          this._dataService.addErrors(response.errors);
        }
      },
      (error: ErrorVO[]) => {
        this._dataService.stopLoading();
        this._dataService.addErrors(error);
      });
  }

  cerrar() {
    this._dialogRef.close();
  }


}
