import { Directive, ElementRef, HostListener } from '@angular/core';
import { REGEX } from '../utils/configUrl';

@Directive({
  selector: '[PhoneCountry]'
})
export class PhoneCountryDirective {
  public phone: string = '';

  @HostListener('keyup', ['$event']) phoneValidate(event: KeyboardEvent){
    if(REGEX.phone.test(event.key)){
      this.phone = `${this.phone}${event.key}`      
    }else if(event.key != 'Backspace'){
      this.input.nativeElement.value = this.phone;
    } else{
      this.phone = this.input.nativeElement.value;
    }
  }
  
  @HostListener('keydown', ['$event']) validateKey(event: KeyboardEvent){
    return REGEX.phone.test(event.key) || event.key === 'Tab' || event.key === 'Backspace';
  }

  constructor(private input:ElementRef) { 
  }
  
}
