import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-edit',
  templateUrl: './button-edit.component.html',
  styleUrls: ['./button-edit.component.css']
})
export class ButtonEditComponent {
  // Recibe el valor para validar si el usuario tiene o no, permisos para visualizar esta funcion.
  @Input() permisosFunciones: any;
  // Recibe el objeto al cual se le desea aplicar la funcion.
  @Input() row: any;
  // Se crea la instancia del EvenEmiter para notificar al componente padre el evento.
  @Output() editarEmit = new EventEmitter<any>();

  constructor() { }

  // Metodo para indicar al componente padre que se ha clickeado en este componente.
  actualizar() {
    this.editarEmit.emit(this.row);
  }
}
