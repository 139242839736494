import { Component, ViewChild, OnChanges } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventInput } from '@fullcalendar/core';
// importaciones para full calendar
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // for dateClick
import { ToolbarInput, ButtonTextCompoundInput } from '@fullcalendar/core/types/input-types';
import { LocaleSingularArg } from '@fullcalendar/core/datelib/locale';
import { TranslateService } from '@ngx-translate/core';
import { SesionService } from '../../../services/sesion.service';

declare const $: any;

@Component({
    selector: 'app-calendar-cmp',
    templateUrl: 'calendar.component.html'
})

export class CalendarComponent {

    public header : ToolbarInput;
    public locale : LocaleSingularArg;
    public buttonText : ButtonTextCompoundInput;
    public allDayText : string;

    @ViewChild('calendar') calendarComponent: FullCalendarComponent; // the #calendar in the template

    calendarVisible = true;
    calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin];
    calendarWeekends = true;
    calendarEvents: EventInput[] = [
        { title: 'Event Now', start: new Date() }
    ];

    constructor(private _translate: TranslateService, private _sesion : SesionService) {
        console.log(this._translate.instant('common.alert.item.created.succes'))
        this.configLanguage();
    }


    /**
     * Configuracion del lenguaje para el fullCalendar
     */
    configLanguage():void{
        let language = this._sesion.getNavigatorLanguage().split('_')[0];


        // TODO: dato en duro... quitar
        language = 'es'
        
        
        this.header = {
            left: 'prev,next, today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        };
        this.locale = language;

        setTimeout(() => {
            if(language === 'es'){
                this.buttonText = {
                    today: this._translate.instant('full.calendar.today'),
                    month: this._translate.instant('full.calendar.month'),
                    week: this._translate.instant('full.calendar.week'),
                    day: this._translate.instant('full.calendar.day')
                };
                this.allDayText = this._translate.instant('full.calendar.allDayText');
            }
        }, 0);
        
        
    }

    toggleVisible() {
        this.calendarVisible = !this.calendarVisible;
    }

    toggleWeekends() {
        this.calendarWeekends = !this.calendarWeekends;
    }

    gotoPast() {
        let calendarApi = this.calendarComponent.getApi();
        calendarApi.gotoDate('2000-01-01'); // call a method on the Calendar object
    }

    handleDateClick(arg) {
        if (confirm('Would you like to add an event to ' + arg.dateStr + ' ?')) {
            this.calendarEvents = this.calendarEvents.concat({ // add new event data. must create new array
                title: 'New Event',
                start: arg.date,
                allDay: arg.allDay
            })
        }
    }
}
