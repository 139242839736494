// general imports
import { Injectable } from '@angular/core';
import { endpoints, notificationMessage } from './../common/utils/configUrl';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material';

// imports VO
import { ResponseVO } from '../common/vo/responseVO';
import { RequestVO } from '../common/vo/requestVO';
import { TipoRolVO } from '../common/vo/tipoRolVO';
import { TipoRolResponseVO } from '../common/vo/tipoRolResponseVO';
import { ErrorVO } from '../common/vo/errorVO';

// services
import { DataService } from './data.service';

// componentes
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { LifeTimeSesionService } from './life-time-session.service';

/**
 * Service created by: irvin uriel
 */

 @Injectable()
export class RolService {
  private urlCreate: string = endpoints.TipoRolCreate;
  private urlFindByAllFields: string = endpoints.TipoRolFindByAllFields;
  private urlDelete: string = endpoints.TipoRolDelete;
  private urlUpdate: string = endpoints.TipoRolUpdate;
  constructor(private _http: HttpClient,
              private _dataService: DataService,
              private _life: LifeTimeSesionService,
              public _dialog: MatDialog) { }

  /**
   * Creación de un tipo rol
   * @param form : contenido para el campo de parameters
   */
  tipoRolCreate(form: TipoRolVO): Observable<ResponseVO<TipoRolResponseVO>> {
    // validar si se refresca el token o continua con el token actual
    this._life.resetToken();

    // crea httpOptions
    let httpOp =  {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this._dataService.getToken() })
    }
    // genera requestVO
    let requestVO = new RequestVO<TipoRolVO>();
    requestVO.parameters = form;
    return this._http
      .post<ResponseVO<TipoRolResponseVO>>(this.urlCreate, requestVO, httpOp)
      .pipe(catchError(this._dataService.handleError));
  }

  /**
   * Creación de un tipo rol
   * @param form : contenido para el campo de parameters
   */
  tipoRolUpdate(form: TipoRolVO): Observable<ResponseVO<TipoRolResponseVO>> {
    // validar si se refresca el token o continua con el token actual
    this._life.resetToken();

    // crea httpOptions
    let httpOp =  {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this._dataService.getToken() })
    }
    // genera requestVO
    let requestVO = new RequestVO<TipoRolVO>();
    requestVO.parameters = form;
    return this._http
      .put<ResponseVO<TipoRolResponseVO>>(this.urlUpdate, requestVO, httpOp)
      .pipe(catchError(this._dataService.handleError));
  }
  
  /**
   * Eliminacion de un tipo rol
   * @param id : id de un dato de tipo rol
   */
  tipoRolDelete(id: number): Observable<ResponseVO<any>> {
   // validar si se refresca el token o continua con el token actual
   this._life.resetToken();

   // crea httpOptions
    let httpOp =  {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this._dataService.getToken() })
    }
    return this._http
      .delete<ResponseVO<any>>(`${this.urlDelete}/${id}`, httpOp)
      .pipe(catchError(this._dataService.handleError));
  }

  /**
   * Busqueda por criterios de un tipo rol
   * @param form : contenido para el campo de parameters
   */
  tipoRolFindByAllFields(parameters: TipoRolVO, page: number, size: number): Observable<ResponseVO<TipoRolResponseVO[]>> {
    // validar si se refresca el token o continua con el token actual
    this._life.resetToken();

    // crea httpOptions
    let httpOp =  {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this._dataService.getToken() })
    }
    // genera requestVO
    let requestVO = new RequestVO<TipoRolVO>();
    requestVO.parameters = parameters;
    requestVO.page = page;
    requestVO.size = size;
    return this._http
      .post<ResponseVO<TipoRolResponseVO[]>>(this.urlFindByAllFields, requestVO, httpOp)
      .pipe(catchError(this._dataService.handleError));
  }

  /**
   * Funcion para confirmar la eliminacion del registro.
   * Invoca un Matdialog para realizar la confirmacion de eliminación.
   */
  eliminarRol(infoRow: TipoRolResponseVO): void {

    let dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: infoRow,
      disableClose: true
    });
    // Espera el cierre de la Modal, lo cual indicará que se aprobo la eliminacion.
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._dataService.startLoading();
        // Se lanza el servicio delete Rol
        this.tipoRolDelete(result.id).subscribe(
          (response) => {
            // Se valida que la transaccion se haya realizado con exito y se notifica,
            // de lo contrario se muestran los errores.
            if (response.success) {
              this._dataService.setFetchData(true);
              this._dataService.setSuccessNotificationMessage(`${infoRow.idNombre} ${notificationMessage.DELETE}`);
            } else {
              this._dataService.addErrors(response.errors);
            }
            this._dataService.stopLoading();
          },
          (error: ErrorVO[]) => {
            this._dataService.stopLoading();
            this._dataService.addErrors(error);
          });
      }
    });

  }
}
