// general imports
import { Injectable } from '@angular/core';
import {Idle} from '@ng-idle/core';
import { Subject, Observable } from 'rxjs';
import { lifeSession } from './../common/utils/configUrl';

// imports VO
import { TokenVO } from '../common/vo/tokenVO';

// Services
import { DataService } from './data.service';
import { SesionService } from './sesion.service';
import { ErrorVO } from '../common/vo/errorVO';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LifeTimeSesionService {
  warningCount= new Subject<number>();

  //observaable para mostrar pop up
  private inactivity = new Subject<boolean>();
  timeout : number;
  timerInit: number;
  refresh: boolean = false;
  timerRefresh: NodeJS.Timer;
  constructor(private idle: Idle, 
              private _data:DataService, 
              private _session: SesionService,
              private router: Router) {
    // Setea un periodo de tiempo de espera antes de finalizar la vida del token.
    idle.setTimeout(lifeSession.count);
    // observable finish
    idle.onTimeout.subscribe(() => {
      this.logOut();
    });
    // observable to set warnings
    idle.onIdleStart.subscribe(() => {
      this.setInactivity(true);
    });
    // observable to count life session
    idle.onTimeoutWarning.subscribe((countdown:number) =>{
      this.warningCount.next(countdown)
    });
  }

  // Metodo para activar el refreshToken
  startTimerRefresh(){
    this.timerRefresh = setInterval(()=>{
      clearInterval(this.timerRefresh);
      this.refresh = true;
    },this.timeout);
  }

  resetToken(): void {
    console.log("resetToken");
    
    if(this.refresh){
      this._session.refreshToken(this._data.getToken()).subscribe( data =>{
        this._data.setToken(data.data.token);
        console.log('Actualizado');
        this.idle.watch();
        this.startTimerRefresh();
        this.refresh = false;
        this.timerRefresh;
      }, 
      // error presentado al no refrescar el token, cerrar sesion.
      (err:ErrorVO) =>{
        this._data.addError(err.message);
        this.logOut();
      });
    }
  }

  getwarningCount():Observable<number>{
    return this.warningCount.asObservable();
  }

  start(token : TokenVO): void {
    this.getLifeToken(token);
    this.idle.watch();
    this.startTimerRefresh();
  }

  stop(): void {
    this.idle.stop();
  }

  setInactivity(inactivity : boolean):void{
    this.inactivity.next(inactivity);
  }

  getInactivity(): Observable<boolean>{
    return this.inactivity.asObservable();
  }

  //calcula tiempo de vida en sesion
  private getLifeToken(token : TokenVO): void{
    //TODO: validar token desde el back para actuaizar nuevo token
    // obtener el tiempo en milisegundos
    let fechaFin = new Date(token.TOKEN_DATA.fechaExpiracion).getTime();
    this.timerInit = new Date(token.TOKEN_DATA.fechaCreacion).getTime();
    //obtener vida del token en milisegundos
    let lifeToken = fechaFin - this.timerInit
    //obtener vida del token en segundos
    lifeToken/=1000

    //se quita 5 minutos de vida del token
    lifeToken-=300
    
    //se obtiene la quinta parte del tiempo para evitar refresh en milisegundos
    this.timeout = lifeToken;
    this.timeout/=5;
    this.timeout = Math.round(this.timeout);
    this.timeout*=1000;

    //la vida del token se le quita un minuto para conteo en pop up.
    lifeToken-=60;
    // sets an idle timeout of N seconds, for testing purposes.
    this.idle.setIdle(lifeToken);
  }

  logOut(){
    this.setInactivity(false);
    this.stop();
    //TODO: mandar token al back para invalidar
    localStorage.clear();
    this._data.setInfoLogin(null);
    this.router.navigateByUrl('');
  }
}
